import sch from '@/service/schedule';
import pub from '@/service/public';
import sup from "@/service/support";
import filter from "@/service/filter/filter.sub";
import router from '@/router';
import Vue from "vue";
import _ from "lodash";

const kmh = 60;
const addTime = (T1, T2) => {
  let Time1 = (T1).toString().split(":");
  let Time2 = (T2).toString().split(":");
  Time2 = Time2.length > 1 ? Time2 : ([Math.trunc(Time2 / 60), Time2 % 60]);
  let hour = (Math.floor(Time1[0] || 0)) + (Math.floor(Time2[0] || 0))
  let min = (Math.floor(Time1[1] || 0)) + (Math.floor(Time2[1] || 0))
  hour = hour + Math.trunc(min / 60);
  min = min % 60;
  return {
    hour: hour,
    min: min,
    format: (hour < 10 ? "0" : "") + hour.toString() + ":" + (min < 10 ? "0" : "") + min
      .toString()
  }
}
const state = {
  addFavorite: false,
  showDayList: {},
  changeLoading: false,
  pointLoading: false,
  showSearchBox: true,
  hideSearchBox: false,
  point: [],
  myQuote: {
    data: [],
    original: []
  },
  movelocation: {
    lastupdate: "",
    data: {},
    openshoedate: ""
  },
  autoreportGPS: {
    isCheck: [],
    mylocation: {
      lat: "",
      lng: "",
    },
    timeout: true
  },
  myschedule: [],
  dayColor: ["#ff6699", "#d56d5f", "#eea357", "#61ab72", "#00b4db", "#5372b7", "#8557a7"],
  quittype: [{
      name: "point",
      icon: "map-marker-alt"
    },
    {
      name: "food",
      icon: "utensils"
    },
    {
      name: "live",
      icon: "home"
    },
    {
      name: "traffic",
      icon: "car"
    },
    {
      name: "fun",
      icon: "shopping-cart"
    }
  ],

  ScheduleInfo: {
    ShareList: [{
        name: "不分享行程",
        value: "noshare"
      },
      {
        name: "分享行程",
        value: "share"
      },
    ]
  },

  SearchData: {
    data: [],
    nextpage_token: "",
    method: "",
    count: 0
  },

  modify: {
    ScheduleList: {}
  },

  witchDayTemp: [],

  edit: {
    strokeAndOtherData: [],
    data: {},
    FirstLocation: {},
    ScheduleList: [],
    stroke: [],
    other: [],
    ToLocation: [],
    ShareLevel: [{
        name: "only_read",
        value: "only_read"
      },
      {
        name: "isEdit",
        value: "isEdit"
      }
    ],

    selectKid: [{
      name: "point",
      value: "point",
      icon: "map-marker-alt",
    }, {
      name: "restaurant",
      value: "restaurant",
      icon: "utensils",
    }, {
      name: "hotel",
      value: "hotel",
      icon: "home",
    }, {
      name: "traffic",
      value: "traffic",
      icon: "car",
    }, {
      name: "shop",
      icon: "shopping-bag",
      value: "shop"
    }, {
      name: "other",
      icon: "cog",
      value: "other"
    }],

    timetype: [{
      name: "Morning",
      value: "Morning",
    }, {
      name: "Afternoon",
      value: "Afternoon",
    }, {
      name: "Night",
      value: "Night",
    }, {
      name: "live",
      value: "live",
    }]
  },

  showPoint: {},
  searchRequest: {},
  personalList: {
    point: [],
    image: [],
    imageTemp: [],
  },

  editPoint: {
    method: "insert",
    dayUid: "",
    data: {},
    selectPoint: {}
  },
}
// getters
const getters = {
  ShoeList(state) {
    const result = [];
    Object.keys(state.movelocation.data).map((item, keys) => {
      let tmp = {
        date: item,
        sub: state.movelocation.data[item]
      };
      result.push(tmp);
    });
    return result;
  },
  GetSchedule(state, data, rootState) {
    let result = [];

    _.each(state.edit.ScheduleList, (item, keys) => {
      _.each(item.sub, (it, ik) => {
        const info = {
          show: it.strokeShow,
          day: keys,
          uid: it.uid,
          place_id: it.place_id,
          lat: it.lat,
          lng: it.lng,
          tagMethod: it.tagMethod,
          maintypes: it.maintypes,
          info: it,
          subshow: item.subshow,
          index: ik,
          name: it.title,
          dataType: it.dataType,
          price: it.tprice || it.price,
          currency: it.currency,
          image: it.images,
          group: it.group
        };
        result.push(info);
      });
    });

    result = _.filter(result, (item, key) => {
      return item.show;
    })

    return result;
  },

}

// actions
const actions = {
  addFavorite({
    state,
    commit,
    dispatch
  }, data) {
    let result = sch.addFavorite(data);
    result.then((data) => {
      const addStatus = data.data.result.status;
      dispatch("Public/addAlert", {
        type: addStatus == "insert" ? "success" : "warning",
        title: "我的最愛",
        msg: addStatus == "insert" ? "已加入我的最愛" : "已存在我的最愛"
      }, {
        root: true
      });
    });
    return result;
  },
  getPersonalImage({
    state,
    commit
  }, data) {
    let result = sch.getPersonalImage(data);
    result.then((data) => {
      commit("setPersonalImage", []);
      if (data.data.state) {
        commit("setPersonalImage", data.data.result);
      }
    });
    return result;
  },
  uploadImage({
    state,
    commit
  }, data) {
    let form = new FormData;

    _.forEach(data.data, (item, key) => {
      _.forEach(item, (items, keys) => {
        if (keys == "files") {
          if (items.length > 0) {
            form.append("data[" + key + "][file]", items[0].file);
          }
        } else {
          form.append("data[" + key + "][" + keys + "]", items);
        }
      });
    });

    const result = sch.uploadImage(data.place, form);
    return result.then(data => {
      commit("setPersonalImage", []);
      if (data.data.state) {
        commit("setPersonalImage", data.data.result);
      }
    })

  },

  getOnePoint({
    commit
  }, uid) {
    commit("setShowPoint", {
      image: []
    });
    console.log(uid);
    const result = sch.getOnePoint(uid);
    result.then(data => {
      if (data.data.state) {
        commit("setShowPoint", data.data.result);
      }
    });
    return result
  },
  getQuote({
    commit,
    dispatch
  }, data) {
    const result = sch.getQuote(data);
    result.then(data => {
      if (data.data.state) {
        commit("setQuoteData", data.data.result);
      }
    });
    return result;
  },
  updateDayListPoint({
    commit,
    dispatch
  }, data) {
    console.log(data)
    const result = sch.updateDayListPoint(data);
    result.then(data => {
      console.log(data);
    });
    return result;
  },
  ShareUrl({
    commit,
    dispatch
  }, data) {
    const result = sch.ShareUrl(data);
    result.then(response => {
      if (response.data.state) {
        router.replace({
          name: "Edit",
          params: {
            Schedule: response.data.result.shareSch
          }
        });
        dispatch("Public/addAlert", {
          title: "成功加入此行程的分享",
          type: "success",
          msg: "成功加入此行程的分享"
        }, {
          root: true
        });
      } else {
        dispatch("Public/addAlert", {
          title: "此行程已關閉分享",
          type: "danger",
          msg: "此行程已關閉分享"
        }, {
          root: true
        });
        router.replace({
          name: "index"
        });
      }
    })
  },
  createSchedule({
    dispatch
  }, data) {
    const method = data.method;
    const tmpdata = data.data;
    const result = sch.createSchedule(method, tmpdata);
    result.then((data) => {});
    return result;
  },
  getSchedule({
    commit
  }, data) {
    const result = sch.getSchedule(data);
    result.then((data) => {
      if (data.data.state) {
        commit("SetSchedule", data.data.result);
      }
    })
    return result;
  },
  getScheduleDay({
    commit,
    dispatch,
    rootState
  }, uid) {
    const result = sch.getScheduleDay(uid);
    result.then((data) => {
      if (data.data.state) {
        if (!data.data.result.Schedule.Edit) {
          dispatch("Public/addAlert", {
            title: "您沒權限修改",
            type: "danger",
            msg: "此行程,您沒權限修改"
          }, {
            root: true
          });
          return;
        }
        commit("SetScheduleDay", data.data.result);
      } else {
        if (rootState.user.Login.state) {
          router.replace({
            name: "index"
          });
        } else {
          dispatch("user/openLoginBox", "", {
            root: true
          });
        }
      }
    })
    return result;
  },
  EditScheduleBtn({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.EditeScheduleInfo(data.uid, data.data);
    result.then((data) => {
      console.log(data);
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "修改行程標題",
          type: "success",
          msg: "修改行程標題為" + data.data.result.Schedule.Title
        }, {
          root: true
        });
      }
    })
    return result;
  },
  SearchData({
    commit,
    dispatch
  }, data) {
    console.log(data);

    commit("setPointLoading", true);
    commit("ClearSearchlList");
    console.log(data.searchtype, data.query, data.method, data.pagetoken);
    const result = sch.getSearchData(data.searchtype, data.query, data.method, data.pagetoken);
    commit("SetSearchList", {
      method: data.searchtype.toLowerCase()
    });
    result.then((data) => {
      if (data.data.state) {
        commit("SetSearchList", data.data);
      } else {
        dispatch("Public/addAlert", {
          title: "搜尋範圍無資料",
          type: "danger",
          msg: "請選擇其他資訊"
        }, {
          root: true
        });
      }
      commit("setPointLoading", false);
    });
  },

  moreSearchData({
    state,
    commit
  }, data) {
    console.log(data);
    commit("setPointLoading", true);

    const result = sch.getSearchData(data.searchtype, data.query, data.method, data.pagetoken);
    result.then((data) => {
      if (data.data.state) {
        commit("SetMoreSearchList", data.data);
      }
      commit("setPointLoading", false);
    });
  },

  AddDay({
    state,
    commit,
    dispatch
  }, data) {
    const result = sch.AddDay(state.edit.data.uid, data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "新增天數",
          type: "success",
          msg: "新增天數完成"
        }, {
          root: true
        });
        commit("SetScheduleDay", data.data.result);
      }
    })

  },
  createStroke({}, data) {
    const selectPoint = data.selectPoint; // 景點來源
    const strokeTemp = data.dataTemp; // 景點資料
  },
  async addSchedule({
    commit,
    state,
    dispatch
  }, data) {
    console.log(data);
    const point = data.point;
    const selectPoint = data.selectPoint;
    const Dayindex = _.findIndex(state.edit.ScheduleList, {
      uid: point.selectDay
    })
    // const stop = Math.floor(parseInt(point.stopTime.hour) * 60 + parseInt(point.stopTime.min));
    const lastsub = state.edit.ScheduleList[Dayindex || 0].sub[state.edit
      .ScheduleList[Dayindex || 0].sub.length - 1];

    const temp = {
      address: selectPoint.address || "",
      lat: selectPoint.lat,
      lng: selectPoint.lng,
      coordinate: point.coordinate,
      selectDay: point.selectDay,
      belongPoint: point.belongPoint,
      belongProduct: point.belongProduct,
      context: point.context,

      description: point.description,
      imageModel: point.imageModel,
      name: point.name,
      personalPrice: point.personalPrice,
      pointName: point.pointName,

      productName: point.productName,
      remark: point.remark,
      timeType: point.timeType,
      type: point.type,
      unit: point.unit,
      unitPrice: point.unitPrice,
      useNumber: point.useNumber,
      stop: 30,
      images: point.images,
      stopTime: point.stopTime || null,
      address: point.address,
      cost: point.cost,
      price: point.price,
      tcost: point.tcost,
      tprice: point.tprice,
      location: point.address,
      country: point.country,
      timezone: point.timezone,
      gmt: point.gmt,
      number: point.number,
      currency: point.currency,
      rate: point.rate,
      strokeShow: point.strokeShow,
      appraiseShow: point.appraiseShow,
    }

    let result = {
      state: false
    };
    try {
      const resultdata = await sch.addSchedule(state.edit.data.uid, {
        data: temp,
        place_info: selectPoint,
        lastsub: lastsub
      });
      result = resultdata.data;
      if (resultdata.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          title: "新增景點",
          msg: "景點名稱:" + temp.name
        }, {
          root: true
        });
        commit("addSchedule", resultdata.data.result);
      }
    } catch (e) {
      console.log(e);
    }


    return result;
  },

  async addStrokeOther({
    dispatch,
    commit,
    state
  }, data) {
    const point = data.point;
    const selectPoint = data.selectPoint;
    const temp = {
      witchDay: point.witchDay,
      coordinate: point.coordinate,
      belongPoint: point.belongPoint,
      belongProduct: point.belongProduct,
      pointName: point.pointName,
      productName: point.productName,
      name: point.name,
      location: selectPoint.address || "",
      description: point.description,
      remark: point.remark,
      timeType: point.timeType,
      lat: selectPoint.lat,
      lng: selectPoint.lng,
      address: point.address,
      price: point.price,
      cost: point.cost,
      tcost: point.tcost,
      tprice: point.tprice,
      location: point.address,
      country: point.country,
      timezone: point.timezone,
      gmt: point.gmt,
      useNumber: point.useNumber,
      unit: point.unit,
      lockPrice: point.lockPrice,
      rate: point.rate,
      currency: point.currency
    }

    let result = {
      state: false
    }
    try {
      const resultdata = await sch.addStrokeOther(state.edit.data.uid, {
        data: temp,
        place_info: selectPoint,
      })
      result = resultdata.data;
      console.log(result);
      // result.then((data) => {
      if (result.state) {
        dispatch("Public/addAlert", {
          type: "success",
          title: "新增費用",
          msg: "費用名稱:" + temp.name
        }, {
          root: true
        });
        commit("setStrokeOther", result.result);
      }
      // });
    } catch (e) {
      return e
    }

    return result;
  },

  async editPoint({
    commit,
    state,
    dispatch
  }, data) {
    let result = {
      state: false
    }

    try {
      const resultdata = await sch.editPoint(state.edit.data.uid, data);
      if (resultdata.data.state) {
        dispatch("Public/addAlert", {
          title: "修正景點",
          type: "success",
          msg: "修正景點完成"
        }, {
          root: true
        });
        commit("seteditPoint", resultdata.data.result);
      }
      result = resultdata.data;
    } catch (e) {
      result.state = false;
    }
    return result;
  },
  async updateStrokeOther({
    commit,
    state,
    dispatch
  }, data) {
    let result = {
      state: false
    }
    try {
      let resultdata = await sch.updateStrokeOther(state.edit.data.uid, data);
      result = resultdata.data
      if (result.state) {
        dispatch("Public/addAlert", {
          title: "修正景點",
          type: "success",
          msg: "修正景點完成"
        }, {
          root: true
        });
        commit("setStrokeOther", result.result);
      }

    } catch (e) {}
    return result;
  },
  async SetChangeSchedule({
    commit,
    state,
    dispatch
  }, data) {
    let result = {
      state: false
    }

    try {
      const resultdata = await sch.changeSchedule(state.edit.data.uid, data);

      if (resultdata.data.state) {
        dispatch("Public/addAlert", {
          title: "調整行程",
          type: "success",
          msg: "修改完成"
        }, {
          root: true
        });

        commit("changeDay", resultdata.data.result);
      };
      //  });
    } catch (e) {
      console.log(e);
    }
    return result;
  },
  deleteDay({
    commit,
    state,
    dispatch
  }, {
    uid,
    dayuid,
    source
  }) {
    const result = sch.deleteSchedule(uid, source, dayuid);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "刪除" + source.method,
          type: "success",
          msg: "刪除" + source.method + ":" + ((source.method == "Point") ? source.data
            .title : source.data.start)
        }, {
          root: true
        });
        commit("changeDay", data.data.result);
      }
    });
    return result;
  },
  async deletePoint({
    commit,
    dispatch,
    state
  }, {
    uid
  }) {
    // this.$store.state.schedule.editPoint.data.selectDay
    console.log(state.editPoint.data.selectDay, uid);
    // return
    return await dispatch("editPoint", {
      day: state.editPoint.data.selectDay,
      point: {
        uid: uid,
        delete: 1
      },
      selectPoint: {}
    });
  },
  checkEmailMember({
    commit,
    state
  }, data) {
    const result = pub.checkEmailMember(data);
    return result;
  },
  deleteSchedule({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.delMySchedule(data);
    result.then(response => {
      dispatch("Public/addAlert", {
        title: "刪除此行程",
        type: "success",
        msg: "刪除此行程-完成"
      }, {
        root: true
      });
    });
    return result;
  },
  CopySchedule({
    commit,
    state
  }, data) {
    const result = sch.CopySchedule(data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "複製行程",
          type: "success",
          msg: "複製行程-完成"
        }, {
          root: true
        });
        commit("SetScheduleDay", data.data.result);
      }
    });
    return result;
  },
  EditStart({
    commit,
    state,
  }, data) {
    const result = sch.EditStart({
      Schdedule: state.edit.data.uid,
      data: data
    });
    result.then(response => {
      commit("SetDayStarttime", response.data);
    })
  },
  StartReport({
    commit,
    state,
    dispatch
  }) {
    const time = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const st = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        commit("getLocation", st);
        if (state.autoreportGPS.timeout) {
          setTimeout(time, 3000);
        }
      })
    };
    commit("StartReport");
    navigator.geolocation.getCurrentPosition((position) => {
      const st = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      commit("getLocation", st);
      setTimeout(time, 3000);
    })
  },
  getScheduleInfo({
    commit,
    dispatch
  }, data) {
    const result = sch.getScheduleInfo(data);
    return result;
  },
  ChangeParallel({
    commit,
    dispatch,
    state
  }, Infodata) {
    const result = sch.ChangeParallel(Infodata);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "變更成功",
          type: "success",
          msg: "變更成功"
        }, {
          root: true
        });
        commit("ChangeParallel", data.data.result);
      } else {
        dispatch("Public/addAlert", {
          title: "更新失敗",
          type: "danger",
          msg: "更新失敗"
        }, {
          root: true
        });
      }
    })
    return result;
  },
  setLocationLog({
    state,
    commit
  }, data) {
    navigator.geolocation.getCurrentPosition((position) => {
      const st = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      sch.setLocationLog(st, state.edit.data.uid).then((data) => {
        commit("setShoeLog", data.data);
      });
    })
  },
  getShoeLog({
    state,
    commit
  }) {
    const result = sch.getShoeLog(state.edit.data.uid, state.movelocation.lastupdate);
    result.then((data) => {
      commit("setShoe", data.data.result);
    });
  },

  transferToTravel({
    state,
    commit
  }, data) {
    const result = sch.transferToTravel(data);
    result.then((data => {
      console.log(data.data);
    }));
  },

  getPoint({
    state,
    commit
  }, data) {
    const result = sch.getPoint(data);
    result.then(data => {
      if (data.data.state) {
        commit("setPoint", data.data.result);
      }
    });
  },

  savePersonalPoint({
    dispatch,
    rootState
  }, data) {
    const user = rootState.user.Login;
    if (user.state) {
      data.belong_user = user.uid;
      const result = sch.savePersonalPoint(data);
      result.then((data) => {
        console.log(data);
      })

      // dispatch("Public/autoSave", {
      //   maintable: "personalPoint",
      //   maindata: data,
      //   sublist: [],
      //   method: 0
      // }, {
      //   root: true
      // });
    }
  },

  getPersonalPoint({
    state,
    commit
  }) {
    const result = sch.getPersonalPoint();
    return result.then(data => {
      commit("setPersonalPoint", []);

      if (data.data.state) {
        _.forEach(data.data.result, (item, key) => {
          item.show = false;
        });

        commit("setPersonalPoint", data.data.result);
      }
    })
  },

  getPersonalPhoto({
    commit
  }) {
    const result = sch.getPersonalPhoto();
    result.then(data => {
      commit("setPersonalImageTemp", []);

      if (data.data.state) {
        commit("setPersonalImageTemp", data.data.result);
      }
    });

    return result;
  },

  sendToCompany({
    state,
    dispatch,
    commit
  }, data) {
    const result = sch.sendToCompany(data);
    result.then(data => {
      if (data.data.state) {
        commit("setChangePersonalPoint", data.data.result);
      } else {
        dispatch("Public/addAlert", {
          title: "失敗",
          type: "danger",
          msg: "推送失敗，請稍後再嘗試"
        }, {
          root: true
        });
      }
    });

    return result;
  },

  updateElement({
    state,
    commit
  }, data) {
    const result = sch.updateElement(data);
    result.then(data => {
      console.log(data);
    });
    return result;
  },

  refreshDayTime({
    state,
    commit
  }, data) {
    const dayinfo = _.cloneDeep(data.day);
    let nextStartTime = dayinfo.starttime
    const belong_day = dayinfo.belong_day;
    dayinfo.sub = _.map(dayinfo.sub, (item, keys) => {
      const stoptime = item.stoptime;
      const startTime = nextStartTime;
      const endTime = addTime(startTime, stoptime).format;
      nextStartTime = addTime(endTime, item.movetime).format;
      nextStartTime = addTime(nextStartTime, (item.moveDiffTime || 0)).format;
      item.starttime = startTime;
      item.endtime = endTime;
      item.belong_day = belong_day;
      return item;
      // endtime,starttime,movetime

    });
    return dayinfo;
  },
  async getDistanceSpeed({}, data) {
    const result = await sch.getDistanceSpeed(data);
    return result;
  },
  addTime(T1, T2) {
    return addTime(T1, T2)
  }
};
// mutations
const mutations = {
  removePoint(state, data) {

    state.edit.ScheduleList[data.key].sub[data.index].delete = !state.edit.ScheduleList[data.key]
      .sub[
        data.index].delete;

    console.log(state.edit.ScheduleList[data.key].sub[data.index].delete);
  },
  setEditPointMethod(state, data) {
    state.editPoint.method = data;
  },
  setStrokeOther(state, data) {
    state.edit.other = _.map(data, (item, key) => {
      let temp = _.find(state.edit.other, {
        uid: item.uid
      });

      item.appraiseShow = (temp && temp.uid) ? temp.appraiseShow : true;
      item.useNumber2 = (temp && temp.uid) ? temp.useNumber2 : '';
      return item;
    });

    this.commit("schedule/setStrokeAndOtherData");
  },
  setEditPoint(state, data) {
    state.editPoint = {
      method: "insert",
      dayUid: "",
      data: {},
      selectPoint: {}
    }

    state.editPoint = data;
  },
  setAddFavorite(state, data) {
    state.addFavorite = data;
    console.log(state.addFavorite);
  },
  setShowDayList(state, data) {
    state.showDayList = data;
  },

  setPersonalImageTemp(state, data) {
    state.personalList.imageTemp = data;
  },

  setPersonalImage(state, data) {
    state.personalList.image = data;
  },
  setSearchRequest(state, data) {
    state.searchRequest = data;
  },

  setChangeLoading(state, data) {
    state.changeLoading = data;
  },

  setPoint(state, data) {
    state.point = data;
  },

  redefault(state, data) {
    state.myschedule = [];
    state.SearchData = {
      data: [],
      nextpage_token: "",
      method: "",
      count: 0,
    };
    state.edit = {
      ScheduleList: [],
      data: {},
      FirstLocation: {},
      ToLocation: [],
    }
    state.showDayList = {};
  },
  SetSchedule(state, data) {
    state.myschedule = [];
    data.map((item, keys) => {
      item.image = `https://picsum.photos/id/94/600/400`;
      item.textcolor = "";
      state.myschedule.push(item);
      return item;
    });
  },
  setQuoteData(state, data) {
    state.myQuote.data = [];
    state.myQuote.original = [];
    state.myQuote.data = data;
    state.myQuote.original = JSON.parse(JSON.stringify(data));
  },
  SetSearchList(state, data) {
    console.log(data);
    let resultdata = [];
    let nextpage = "";
    let count = 0;
    if (data && data.result) {
      resultdata = data.result.results;
      nextpage = data.result.next_page_token || "";
      count = data.result.count;
    }
    state.SearchData.count = count;
    state.SearchData.data = resultdata;
    state.SearchData.nextpage_token = nextpage;
    state.SearchData.method = data.method || "";
    state.hideSearchBox = true;
    state.showSearchBox = true;
  },

  SetMoreSearchList(state, data) {
    const resultdata = data.result.results;
    state.SearchData.nextpage_token = data.result.next_page_token;
    state.SearchData.data = state.SearchData.data.concat(resultdata);
  },

  ClearSearchlList(state) {
    state.SearchData.count = 0;
    state.SearchData.data = [];
    state.SearchData.nextpage_token = "";
    state.SearchData.method = "";
  },
  SetChangeSchedule(state, data) {
    state.edit.ScheduleList = _.cloneDeep(data);
    state.modify.ScheduleList = _.cloneDeep(data);
  },

  addSchedule(state, data) {
    state.edit.ScheduleList[parseInt(data.Day)].sub = _.orderBy(_.map(data.DayPoint, (items,
      keys) => {
      items.imageShow = false;
      items.sourceShow = false;
      items.selectimage = 0
      return items;
    }), ["orders"], ["asc"]);
    state.modify.ScheduleList = _.cloneDeep(state.edit.ScheduleList);
  },

  SetScheduleDay(state, data) {
    state.modify.ScheduleList = _.cloneDeep(data.Day);
    state.edit.data = data.Schedule;
    state.edit.data.groupName = state.edit.data.groupName ?? [{
      name: "無群組",
      values: ""
    }, {
      name: "A組",
      values: "A"
    }, {
      name: "B組",
      values: "B"
    }, {
      name: "C組",
      values: "C"
    }, {
      name: "D組",
      values: "D"
    }, {
      name: "E組",
      values: "E"
    }, {
      name: "F組",
      values: "F"
    }, {
      name: "G組",
      values: "G"
    }];
    state.edit.ScheduleList = _.map(data.Day, (item, key) => {
      item.subshow = false;
      item.titleShow = false;
      item.sub = _.orderBy(_.map(item.sub, (items, keys) => {
        items.delete = false;
        items.imageShow = false;
        items.sourceShow = false;
        items.selectimage = 0
        return items;
      }), ["orders"], ["asc"]);
      return item
    });

    state.showDayList = state.edit.ScheduleList[0];
    state.edit.ToLocation = data.ToLocation;
    state.edit.FirstLocation = data.FirstLocation;

    state.edit.other = _.map(data.other, (item, key) => {
      // item.appraiseShow = true; // ok
      return item;
    });

    const temp = [{
      name: "無設定",
      values: ""
    }];
    _.each(state.edit.ScheduleList, (item, key) => {
      temp.push({
        name: "第" + (key + 1) + "天",
        values: key + 1
      });
    });
    state.witchDayTemp = temp;

    let stroke = [];
    _.each(_.cloneDeep(state.edit.ScheduleList), (item, key) => {
      _.each(item.sub, (items, keys) => {
        // items.appraiseShow = true; // ok
        stroke.push(items);
      });
    });
    state.edit.stroke = stroke;
    state.edit.strokeAndOtherData = _.orderBy(_.map(_.concat(state.edit.stroke, state.edit.other),
      (item, key) => {
        const temp = _.find(state.edit.ScheduleList, {
          uid: item.witchDay
        });
        item.witchDay = temp ? parseInt(temp.orders) + 1 : "";
        return item;
      }), [
      "witchDay"
    ], ["asc"]);
  },
  setStrokeData(state, data) {
    let stroke = [];
    _.each(_.cloneDeep(state.edit.ScheduleList), (item, key) => {
      _.each(item.sub, (items, keys) => {
        let temp = _.find(state.edit.stroke, {
          uid: items.uid
        });
        // items.appraiseShow = (temp && temp.uid) ? temp.appraiseShow : true;
        items.useNumber2 = (temp && temp.uid) ? temp.useNumber2 : '';
        stroke.push(items);
      });
    });
    state.edit.stroke = stroke;
  },

  setOtherData(state, data) {
    state.edit.other = _.map(data, (item, key) => {
      let temp = _.find(state.edit.other, {
        uid: item.uid
      });
      item.appraiseShow = (temp && temp.uid) ? temp.appraiseShow : true;
      item.useNumber2 = (temp && temp.uid) ? temp.useNumber2 : '';
      return item;
    });
  },

  setStrokeAndOtherData(state, data) {
    state.edit.strokeAndOtherData = _.orderBy(_.map(_.concat(state.edit.stroke, state.edit.other),
      (item, key) => {
        const temp = _.find(state.edit.ScheduleList, {
          uid: item.TravelDayList
        });

        item.witchDay = temp ? parseInt(temp.orders) + 1 : "";
        return item;
      }), ["witchDay"], ["asc"]);
  },
  changeDay(state, data) {
    const sList = _.cloneDeep(state.edit.ScheduleList);
    state.edit.ScheduleList = state.modify.ScheduleList = _.map(data.Day, (item, key) => {

      const iot = _.find(sList, {
        uid: item.uid
      });
      item.subshow = iot ? iot.subshow : false;
      item.titleShow = iot ? iot.titleShow : false;

      iot.subshow = iot ? iot.subshow : false;

      item.sub = _.map(item.sub, (items, keys) => {
        const subuid = _.find(iot ? iot.sub : [], {
          uid: items.uid
        });
        const ck = (subuid) ? (subuid.imageShow || false) : false;
        const dk = (subuid) ? (subuid.sourceShow || false) : false;
        items.imageShow = ck;
        items.sourceShow = dk;
        items.selectimage = 0;
        items.delete = false;
        return items;
      });

      return item;
    });

    console.log(state.edit.ScheduleList);

    this.commit("schedule/setStrokeData");
    this.commit("schedule/setStrokeAndOtherData");
  },
  seteditPoint(state, data) {
    _.each(data.daylist, (dayInfo) => {
      const DayIndex = parseInt(dayInfo.orders)
      const newdata = _.cloneDeep(state.edit.ScheduleList[DayIndex]);

      dayInfo.subshow = newdata.subshow || false;
      dayInfo.titleShow = newdata.titleShow || false;
      dayInfo.sub = _.orderBy(_.map(dayInfo.sub, (subitem) => {
        const oldSubData = _.find(newdata.sub, {
          uid: subitem.uid
        }) || {
          imageShow: false,
          sourceShow: false
        };
        subitem.imageShow = oldSubData.imageShow;
        subitem.sourceShow = oldSubData.sourceShow;
        subitem.selectimage = 0
        return subitem;
      }), ["orders"], ["asc"])
      state.edit.ScheduleList[DayIndex] = dayInfo;
    });

    this.commit("schedule/setStrokeData");
    this.commit("schedule/setStrokeAndOtherData");
  },
  SetDayStarttime(state, response) {
    if (response.state) {
      const data = response.result;
      const dayTemp = _.cloneDeep(data.Day[0]);
      const dayUid = dayTemp.uid;

      state.edit.ScheduleList = _.map(state.edit.ScheduleList, (item, key) => {
        if (item.uid == dayUid) {
          item.name = dayTemp.name;
          item.starttime = dayTemp.starttime;
          item.sub = _.map(dayTemp.sub, (items, keys) => {
            const temp = _.find(item.sub, {
              uid: items.uid
            });
            items.delete = false;
            items.imageShow = temp ? temp.imageShow : false;
            items.sourceShow = temp ? temp.sourceShow : false;
            items.selectimage = 0
            return items;
          });
        }

        return item;
      });

      state.modify.ScheduleList = _.map(state.modify.ScheduleList, (item, key) => {
        if (item.uid == dayUid) {
          item.name = dayTemp.name;
          item.starttime = dayTemp.starttime;
          item.sub = _.map(dayTemp.sub, (items, keys) => {
            const temp = _.find(item.sub, {
              uid: items.uid
            });

            console.log(temp);
            items.imageShow = temp ? temp.imageShow : false;
            items.sourceShow = temp ? temp.sourceShow : false;
            items.selectimage = 0;
            return items;
          });
        }

        return item;
      });
    }
  },
  getLocation(state, data) {
    state.autoreportGPS.mylocation = {
      lat: data.lat,
      lng: data.lng
    }
  },
  StopReport(state) {
    state.autoreportGPS.timeout = false;
  },
  StartReport(state) {
    state.autoreportGPS.timeout = true;
  },

  ChangeParallel(state, data) {
    state.edit.ScheduleList = state.modify.ScheduleList = state.edit.ScheduleList.map((item,
      keys) => {
      if (item.uid == data.Day) {
        item.sub = item.sub = data.DayPoint
      }
      return item;
    });
  },

  setShoe(state, data) {
    state.movelocation.lastupdate = data.gettime;
    state.movelocation.data = data.data;
  },

  setShoeLog(state, data) {
    const reuslt = data.result;
    if (state.movelocation.lastupdate) {
      state.movelocation.data[reuslt.date] = (state.movelocation.data[reuslt.date]) ? state
        .movelocation.data[reuslt.date] : []
      state.movelocation.data[reuslt.date].splice(0, 0, reuslt.data);
    }
  },

  setOpenShoeDate(state, data) {
    state.movelocation.openshoedate = data;
  },

  setPointLoading(state, data) {
    state.pointLoading = data;
  },

  setShowSearchBox(state, data) {
    state.showSearchBox = data;
  },
  setHideSearchBox(state, data) {
    state.hideSearchBox = data;
  },

  setOneScheduleDay(state, data) {
    const temp = JSON.parse(JSON.stringify(data.result));

    _.forEach(state.edit.ScheduleList, (item, key) => {
      if (item.uid == temp.uid) {
        item.googleDistance = temp.googleDistance;
        item.sub = temp.sub;
      }
    });

    _.forEach(state.modify.ScheduleList, (item, key) => {
      if (item.uid == temp.uid) {
        item.googleDistance = temp.googleDistance;
        item.sub = temp.sub;
      }
    });
  },

  setShowPoint(state, data) {
    // let point = {};
    state.showPoint = data;

    return;
    temp.more = false;
    temp.model = "image";
    temp.streetAndImage = [];
    state.showPoint = temp;
  },

  setPersonalPoint(state, data) {
    state.personalList.point = data;
  },

  setChangePersonalPoint(state, data) {
    let index = _.findIndex(state.personalList.point, (item, key) => {
      return item.uid == data.belong_ele;
    });

    if (index > -1) {
      let subIndex = _.findIndex(state.personalList.point[index].sub, (item, key) => {
        return item.uid == data.uid;
      });

      if (subIndex > -1) {
        Vue.set(state.personalList.point[index].sub, subIndex, data);
      }
    }
  },

  setInsertPersonalPoint(state, data) {
    let index = _.findIndex(state.personalList.point, (item, key) => {
      return item.uid == data.belong_ele;
    });
    if (index > -1) {
      state.personalList.point[index].sub.push(data);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
