<template>
	<div id="app">
		<div class="w-100 h-100 position-absolute bga-2 d-flex align-items-center justify-content-center" style="z-index: 2000;" v-if="loadList.length > 0">
			<div class="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>

		<router-view />
		<div class="fixed-bottom pr-3 float-right col-lg-4 col-sm-12 offset-lg-8 best-u">
			<b-toast :title="alertbox.title" toaster="b-toaster-top-center" v-for="(alertbox, key) in alertBox" :variant="alertbox.type" visible :auto-hide-delay="alertbox.time" :key="'SS__' + key">
				{{ alertbox.msg }}
			</b-toast>

			<LoginBox></LoginBox>

			<b-modal v-model="openLogin" centered size="sm" @hide="resetLogin()" :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']" :footer-class="['p-0', 'bg-white']">
				<template v-slot:modal-header="{ close }">
					<div class="w-100 d-flex align-items-center justify-content-between">
						<div class="flex-fill">
							<b>會員管理</b>
						</div>
						<div @click="close()">
							<font-awesome-icon :icon="['fas', 'times']" size="lg" />
						</div>
					</div>
				</template>

				<template v-slot:default="{ close }">
					<div class="w-100">
						<div class="w-100" v-if="!loginStatus.state">
							<div class="w-100 bg-light p-3">
								<b-form-group label-cols="2" label="帳號" label-class="py-0 text-center align-self-center text-black">
									<b-form-input v-model="loginData.account" class="px-2 font-weight-normal" placeholder="請輸入電子信箱"> </b-form-input>
								</b-form-group>

								<b-form-group class="m-0" label-cols="2" label="密碼" label-class="py-0 text-center align-self-center text-black">
									<b-form-input v-model="loginData.password" type="password" class="px-2 font-weight-normal" placeholder="請輸入6 ~ 12碼字元"> </b-form-input>
								</b-form-group>
							</div>

							<div class="d-flex justify-content-around p-3">
								<button class="btn btn-sm bg-dagee text-white px-4">
									註冊會員
								</button>

								<button class="btn btn-sm btn-danger px-4">
									忘記密碼
								</button>
							</div>
						</div>
						<div class="w-100" v-if="loginStatus.state">
							<div class="d-flex align-items-center justify-content-center bg-light p-3">
								<b>歡迎，{{ loginStatus.name }}</b>
							</div>
						</div>
					</div>
				</template>

				<template v-slot:modal-footer="{ close }">
					<div class="w-100 d-flex align-content-center justify-content-around">
						<button class="btn btn-sm bg-dagee text-white px-4" @click="Login()" v-if="!loginStatus.state">
							<b>登入</b>
						</button>

						<button class="btn btn-sm bg-dagee text-white px-4" @click="logout(close)" v-if="loginStatus.state">
							<b>登出</b>
						</button>

						<button class="btn btn-sm btn-danger px-4" @click="close">
							<b>關閉</b>
						</button>
					</div>
				</template>
			</b-modal>
		</div>
	</div>
</template>
<script>
import Vue from "vue";
import store from "@/store";
import Loading from "vue-loading-overlay";
import LoginBox from "@/components/LoginBox";
import ReSignPage from "@/components/ReSignPage.vue";
import ForgetPW from "@/components/ForgetPW.vue";
import Birth from "@/components/Input/Birth";
Vue.use(Loading);

export default {
	data() {
		return {
			resignpage: false,
			refreshing: false,
			registration: null,
			updateExists: false,
			pub: store.state.Public,
			loginData: {
				account: "",
				password: "",
			},

			page: this.part || 1,

			pwdcheck: false,
			emailcheck: false,
			postcode: store.state.Public.postcode,
			thischeck: [],
			data: {
				account: "",
				password: "",
				name: "",
				googleuid: "",
				email: "",
				phone: "",
				engname: "",
				postelcode: "",
				location: "",
				gender_id: "",
				birth: {
					year: "",
					month: "",
					date: "",
				},
				photo: "",
			},
		};
	},
	computed: {
		loadList: {
			get() {
				// return false;
				return this.$store.state.Public.loadList;
			},
		},

		loginStatus: {
			get() {
				return this.$store.state.user.Login;
			},
		},

		openLogin: {
			get() {
				return this.$store.state.user.openLogin;
			},

			set(status) {
				this.$store.dispatch("user/openLogin", status);
			},
		},

		alertBox: {
			get() {
				return store.state.Public.alertbox;
			},
		},

		lang: {
			get() {
				return store.state.Public.lang.resign;
			},
		},

		langpub: {
			get() {
				return store.state.Public.lang.public;
			},
		},

		passwordshow() {
			return this.data.password.slice(0, 4) + "*******";
		},
	},

	components: {
		LoginBox,
		ReSignPage,
		ForgetPW,
		Birth,
	},
	methods: {
		Login() {
			store
				.dispatch("user/login", {
					method: "Account",
					data: {
						email: this.loginData.account,
						password: this.loginData.password,
					},
				})
				.then((data) => {
					if (data.data.state) {
						store.dispatch("schedule/getSchedule", "MySchedule");

						if (this.$route.name == "Login") {
							this.$router.push({
								name: "index",
							});
						} else {
							this.$router.go(0);
						}
					}
				});
		},

		logout(close) {
			this.$router.push({
				name: "logout",
			});

			close();
		},

		resetLogin() {
			this.loginData = {
				account: "",
				password: "",
			};
		},

		loginStatus: {
			get() {
				return this.$store.state.user.Login;
			},
		},

		openLogin: {
			get() {
				return this.$store.state.user.openLogin;
			},

			set(status) {
				this.$store.dispatch("user/openLogin", status);
			},
		},

		alertBox: {
			get() {
				return store.state.Public.alertbox;
			},
		},

		lang: {
			get() {
				return store.state.Public.lang.resign;
			},
		},

		langpub: {
			get() {
				return store.state.Public.lang.public;
			},
		},

		passwordshow() {
			return this.data.password.slice(0, 4) + "*******";
		},
	},

	components: {
		LoginBox,
		ReSignPage,
		ForgetPW,
		Birth,
	},

	async mounted() {
		console.log("build");
		
		await this.$store.dispatch("Public/getMountPublicData");
		
		document.addEventListener("swUpdated", this.showRefreshUI, {
			once: true,
		});

		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (this.refreshing) return;
			this.refreshing = true;
			window.location.reload();
		});
	},

	methods: {
		Login() {
			store
				.dispatch("user/login", {
					method: "Account",
					data: {
						email: this.loginData.account,
						password: this.loginData.password,
					},
				})
				.then((data) => {
					if (data.data.state) {
						store.dispatch("schedule/getSchedule", "MySchedule");

						if (this.$route.name == "Login") {
							this.$router.push({
								name: "index",
							});
						} else {
							this.$router.go(0);
						}
					}
				});
		},

		logout(close) {
			this.$router.push({
				name: "logout",
			});

			close();
		},

		resetLogin() {
			this.loginData = {
				account: "",
				password: "",
			};
		},
		next(page) {
			if (page == 1 && this.data.email != "" && this.data.password) {
				store
					.dispatch("user/resign", {
						method: "check",
						data: {
							email: this.data.email,
						},
					})
					.then((data) => {
						if (data.data.result.msg) {
							this.page = 999;
						} else {
							this.data.account = this.data.email;
							this.page++;
						}
					});
			} else if (page == 2) {
				const checkfield = ["birth", "name", "phone", "gender_id"];
				this.thischeck = checkfield
					.map((item, keys) => {
						if (this.data[item]) {
							if (item == "birth") {
								if (this.data[item].year && this.data[item].month && this.data[item].date) {
									return;
								} else {
									return item;
								}
							} else {
								return;
							}
						} else {
							return item;
						}
					})
					.filter((item) => {
						return item;
					});

				if (this.thischeck.length == 0) {
					this.page++;
					this.thischeck = [];
				} else {
					store.dispatch("Public/addAlert", {
						type: "danger",
						title: "請務必填寫",
						msg: "此區欄位都是必填",
					});
				}
			} else {
				this.page++;
			}
		},

		pre(page) {
			if (page == 2) {
				this.data.account = this.data.email;
				this.data.password = "";
				this.page--;
			} else if (page == 999) {
				this.page = 1;
			} else {
				this.page--;
			}
		},

		finish() {
			const result = store.dispatch("user/resign", {
				method: "finish",
				data: this.data,
			});
			result.then((data) => {
				if (data.data.result.inMember) {
					store.dispatch("Public/addAlert", {
						type: "success",
						title: "註冊完成",
						msg: "註冊完成",
					});
					if (this.caseOI) {
						this.caseOI(data.data.result);
					}
					this.Close();
				}
			});
		},
	},
};
</script>

<style lang="scss">
#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}
</style>
