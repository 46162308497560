import { render, staticRenderFns } from "./ForgetPW.vue?vue&type=template&id=46a7d843&scoped=true&"
import script from "./ForgetPW.vue?vue&type=script&lang=js&"
export * from "./ForgetPW.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a7d843",
  null
  
)

export default component.exports