import pbAx from "@/service/public";
import {
  _
} from "core-js";

const import_language = (lang, path = "lang") => {
  return import(`@/lang/${lang}`);
}

const state = {
  finderList: {
    finder: [],
    files: [],
    concatFinder: [],
  },
  pointImage: [],
  click: {
    timer: "",
    flag: true,
  },

  loadList: [],
  createNewPoint: false,

  icon: {
    "": ["fas", "cog"],
    point: ["fas", "map-marker-alt"],
    restaurant: ["fas", "utensils"],
    food: ["fas", "utensils"],
    hotel: ["fas", "home"],
    live: ["fas", "home"],
    traffic: ["fas", "bus"],
    other: ["fas", "cog"],
    shop: ["fas", "shopping-bag"]
  },

  moveTemp: [{
    name: "車",
    values: "car",
    icon: ["fas", "car"],
  }, {
    name: "遊覽車",
    values: "bus",
    icon: ["fas", "bus"],
  }, {
    name: "飛機",
    values: "plane",
    icon: ["fas", "plane"],
  }, {
    name: "船",
    values: "ship",
    icon: ["fas", "ship"],
  }, {
    name: "步行",
    values: "walk",
    icon: ["fas", "walking"],
  }],

  groupTemp: [{
    name: "不分組",
    values: ""
  }, {
    name: "A",
    values: "A"
  }, {
    name: "B",
    values: "B"
  }, {
    name: "C",
    values: "C"
  }, {
    name: "D",
    values: "D"
  }, {
    name: "E",
    values: "E"
  }, {
    name: "F",
    values: "F"
  }, {
    name: "G",
    values: "G"
  }],

  publicData: {
    country: [],
    city: [],
    postcode: [],
    unit: [],
    favorite: [],
    currency: [],
    tourDeparture: [],
    tourAppraisalType: [],
    language: [],
    tourElementClass: []
  },

  lang: {},
  headerShow: false,
  searchShow: false,
  openSearch: false,
  searchBar: false,
  smopen: true,
  myPoint: [null, null],
  alertbox: [],
  eleType: [],
  Country: [],
  city: [],
  postcode: [],
}

// getters

// actions
const actions = {
  async autoSave({
    commit
  }, {
    maintable = "",
    maindata = {},
    sublist = [],
    method = 0
  }) {
    const tdata = {
      maintable: maintable,
      maindata: maindata,
      sublist: sublist || [],
      method: method || 0 // 1 主表多筆新增 0 主表只能新增一筆 2 複數筆父子資料新增
    };

    console.log(tdata);
    return await pbAx.autoSave(tdata).then((data) => {
      return data.data;
    })
  },
  getMyLocation({
    dispatch,
    commit
  }, method) {
    const result = navigator.geolocation;
    result.getCurrentPosition((data) => {
      const myPoints = data;
      const itude = [data.coords.latitude, data.coords.longitude];

      commit("setMyLocation", itude);

      if (method) {
        dispatch("map/changeMapCenter", itude, {
          root: true
        });
      }
      //   this.myPoint = itude;
      //   this.$store.dispatch("map/changeMapCenter", itude);
    }, (error) => {
      dispatch("addAlert", {
        title: "您的裝置不支援系統定位",
        type: "danger",
        msg: error.message
      })
    });

    return result;
  },

  addAlert({
    commit
  }, data) {
    // type: data.type,
    // title: data.title,
    // msg: data.msg,
    // time: 3500
    // return ;
    commit("addalertbox", data);
    setTimeout(function () {
      commit("removealertbox", 0);
    }, 5000);
  },

  lang({
    commit,
    dispatch
  }, lang) {
    import_language(lang).then((data) => {
      commit("setlang", data.default);
    });
  },

  PostCode({
    commit,
    dispatch,
    state
  }) {
    if (state.postcode.length > 0) {
      return;
    } else {
      const result = pbAx.PostCode();
      result.then(function (data) {
        const result = data.data.result;
        commit("setPostCode", result);
      });
      return result;
    }
  },

  SearchPostCode({
    commit
  }, data) {
    const result = pbAx.SearchPostCode(data.Country, data.City);
    return result;
  },

  Country({
    commit,
    state
  }) {
    if (state.postcode.length > 0) {
      return;
    } else {
      let result;
      if (state.Country.length == 0) {
        result = pbAx.Country();
        result.then(function (data) {
          const result = data.data.result;
          commit("setCountry", result);
        });
      }
      return result;
    }
  },

  // new 0914

  getdataObject({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result;
  },
  getMultiDataObject({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getMultiDataObject(search);

    return result;
  },

  getType({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setType", data.data.data);
    });
  },

  getCountry({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setCountry", data.data.data);
    });
  },

  getCity({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setCity", data.data.data);
    });
  },

  getPostCode({
    commit
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setPostCode", data.data.data);
    });
  },

  finderList({
    commit
  }, data) {
    const result = pbAx.finderList(data);
    result.then((data) => {
      commit("setFinderList", data.data);
    });
    return result;
  },

  uploadFile({
    commit
  }, data) {
    const result = pbAx.uploadfile(data);
    result.then((data) => {});
    return result;
  },
  async GetWebImage({
    commit,
    dispatch
  }, data) {
    return await pbAx.GetWebImage(data.url)
  },
  async getMountPublicData({
    commit,
    dispatch,
    rootState
  }) {
    // console.log(rootState.user.Login.state);
    // if (!rootState.user.Login.state) {
    //   return;
    // };

    const mulitData = await dispatch("getMultiDataObject", [{
          table: "Country",
          page: 0,
          pagenumber: "all",
          search: {
            sametext: {
              online: "1",
            },
          },
          orderBy: {
            name: "orders",
            order: "asc",
          },
          defaultfield: ["uid", "name", "smallEng as code"],
        },
        {
          table: "City",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          defaultfield: ["uid", "name", "code", "countryTag"],
        },
        {
          table: "post_code",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          defaultfield: ["uid", "area_name as name", "area_uid as code", "City"],
        },
        {
          table: "public_class",
          page: 0,
          pagenumber: "all",
          search: {
            sametext: {
              func: "unit",
            },
          },
          cancelfunc: "getUnit",
        },
        {
          table: "favoriteFolder",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          cancelfunc: "getFavoritFolder",
        },
        {
          table: "currency",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          search: {
            sametext: {
              online: "1",
            },
            raw: ["currency.rate1 > 0"]
            // number: {
            // 	rate1: {
            // 		start: 0,
            // 	},
            // },
          },
          cancelfunc: "getCurrency",
        },
        {
          table: "TourDeparture",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          cancelfunc: "getTourDeparture",
        },
        {
          table: "TourAppraisalType",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          cancelfunc: "getTourAppraisalType",
        },
        {
          table: "language",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          cancelfunc: "getLanguage",
        },
        {
          table: "timeZone",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "timezone",
            order: "asc",
          },
          cancelfunc: "getTimezone",
        },
        {
          table: "TourElementClass",
          page: 0,
          pagenumber: "all",
          orderBy: {
            name: "orders",
            order: "asc",
          },
          cancelfunc: "getTourElementClass2",
        },
      ])
      .then((data) => {
        return data.data;
      });

    const keyBymulitData = _.keyBy(mulitData, "table");
    commit("setData", {
      state: "publicData.country",
      data: keyBymulitData["Country"].data,
    });
    commit("setData", {
      state: "publicData.city",
      data: keyBymulitData["City"].data,
    });
    commit("setData", {
      state: "publicData.postcode",
      data: keyBymulitData["post_code"].data,
    });
    commit("setData", {
      state: "publicData.unit",
      data: keyBymulitData["public_class"].data,
    });
    commit("setData", {
      state: "publicData.favorite",
      data: keyBymulitData["favoriteFolder"].data,
    });
    commit("setData", {
      state: "publicData.currency",
      data: keyBymulitData["currency"].data,
    });
    commit("setData", {
      state: "publicData.tourDeparture",
      data: keyBymulitData["TourDeparture"].data,
    });
    commit("setData", {
      state: "publicData.tourAppraisalType",
      data: keyBymulitData["TourAppraisalType"].data,
    });
    commit("setData", {
      state: "publicData.language",
      data: keyBymulitData["language"].data,
    });
    commit("setData", {
      state: "publicData.timezone",
      data: keyBymulitData["timeZone"].data,
    });
    commit("setData", {
      state: "publicData.tourElementClass",
      data: keyBymulitData["TourElementClass"].data,
    });

    return mulitData;
  }
};

const mutations = {
  setType(state, data) {
    state.eleType = data;
  },

  setCountry(state, data) {
    state.Country = data;
  },

  setCity(state, data) {
    state.city = data;
  },

  setPostCode(state, data) {
    state.postcode = data;
  },

  setMyLocation(state, data) {
    state.myPoint = data;
  },

  setlang(state, data) {
    state.lang = data;
  },

  addalertbox(state, data = {
    type: String,
    title: String,
    msg: String,
    time: Number
  }) {
    state.alertbox.push({
      type: data.type,
      title: data.title,
      msg: data.msg,
      time: data.time || 400
    });
  },

  removealertbox(state, index) {
    state.alertbox.splice(index, 1);
  },

  setSearchBox(state, show) {
    state.searchShow = show;
  },

  setOpenSearch(state, show) {
    state.openSearch = show;
  },

  setHeaderShow(state, show) {
    state.headerShow = show;
  },

  setSmopen(state, show) {
    state.smopen = show;
  },

  setData(state, temp) {
    const path = temp.state.split(".");
    let ref = state;

    _.each(path, (item, key) => {
      if (!ref[item]) {
        ref[item] = key == path.length - 1 ? "" : {};
      }

      ref[item] = key == path.length - 1 ? temp.data : ref[item];
      ref = ref[item];
    });
  },

  setCreateNewPoint(state, data) {
    state.createNewPoint = data;
  },

  setLoadList(state, data) {
    if (data.method == "add") {
      state.loadList.push(data.number);
    }

    if (data.method == "remove") {
      const index = state.loadList.indexOf(data.number);
      if (index > -1) {
        state.loadList.splice(index, 1);
      }
    }
  },

  setPointImage(state, data) {
    state.pointImage = _.map(data, (item, key) => {
      item.url = item.linkname.image;
      item.supply = item.createCompany;
      return item;
    });
  },

  setFinderList(state, data) {
    state.finderList.finder = _.map(data.finder, (item, key) => {
      item.type = "field";
      return item;
    });

    state.finderList.files = _.map(data.files, (item, key) => {
      item.type = "file";
      return item;
    });

    state.finderList.concatFinder = _.concat(state.finderList.finder, state.finderList.files);
  },

};

const getters = {
  formatSmallClass(state) {
    let smallClass = state.publicData.tourElementClass;
    smallClass = _.groupBy(smallClass, "func");
    const temp = _.map(state.eleType, (item, key) => {
      item.smallClass = smallClass[item.values];
      return item;
    });

    return temp;
  },

  concatFinder(state) {
    return () => {
      return _.concat(state.finderList.finder, state.finderList.files);
    }
  },
  passIcon(state) {
    return _.keyBy(state.moveTemp, "values");
  },

  showIcon(state) {
    return (icon) => {
      return state.icon[icon] || [];
    }
  },

  returnPriceStatus() {
    return (price, rate = 1) => {
      let status = "";
      switch (price) {
        case "":
          status = "未提供";
          break;
        case 0:
        case "0":
          status = "免費";
          break;
        default:
          status = price;
          status = (status / rate).toFixed(2);
          status = parseFloat(status);
          break;
      }
      return status;
    }
  },

  getUnit(state) {
    return (unit) => {
      const unitTemp = _.find(state.publicData.unit, (item, key) => {
        return item.values == unit;
      });

      return unitTemp ? unitTemp.name : "";
    }
  },

  getCountry(state) {
    return (country) => {
      return _.find(state.publicData.country, {
        uid: country
      }) || {};
    }
  },

  getCurrency(state) {
    return (currency = "TWD", tranCurrency) => {
      let cTemp = state.publicData.currency;

      const temp = _.find(cTemp, {
        code: currency
      }) || {};

      return temp;
    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
