import pub from '@/service/public';

const state = {
  newCreatePoint: [],
  moreSearchShow: false,

  searchType: [{
    name: "全部",
    values: "",
    icon: ["fas", "globe"],
  }, {
    name: "景點",
    values: "point",
    icon: ["fas", "map-marker-alt"]
  }, {
    name: "餐廳",
    values: "restaurant",
    icon: ["fas", "utensils"]
  }, {
    name: "住宿",
    values: "hotel",
    icon: ["fas", "home"]
  }],

  searchData: {
    type: "preference",
    method: "all",
    eleType: "",
    class: "",
    keywords: "",
    favorite: "",
    country: "",
    city: "",
    lat: "",
    lng: "",
    smallClass: [],
  },

  searchTemp: {
    range: false
  },

  searchResult: {
    type: "preference",
    data: [],
    count: 0,
    nextpage_token: "",
    searchRange: []
  }
}

const getters = {
  getCity(state, getter, rootState) {
    if (state.searchData.country) {
      return _.filter(rootState.Public.publicData.city, (item, key) => {
        return item.countryTag == state.searchData.country;
      });
    }

    return [];
  },

  getPostcode(state, getter, rootState) {
    if (state.searchData.city) {
      return _.filter(rootState.Public.publicData.postcode, (item, key) => {
        return item.City == state.searchData.city;
      });
    }

    return [];
  },

  getSearchRule(state, getter, rootState) {
    let rule = [];

    const field = [{
      field: "keywords",
      name: "關鍵字"
    }, {
      field: "favorite",
      name: "最愛"
    }, {
      field: "country",
      name: "國家"
    }, {
      field: "city",
      name: "城市"
    }];

    _.each(field, (item, key) => {
      let temp = {};
      if (state.searchData[item.field]) {
        temp.field = item.field;
        temp.fieldName = item.name;

        if (item.field == "keywords") {
          temp.fieldValue = state.searchData[item.field];
        } else {
          temp.fieldValue = _.find(rootState.Public.publicData[item.field], {
            uid: state.searchData[item.field]
          }).name;
        }

        if (item.field == "favorite") {
          if (state.searchData.method == "favorite") {
            rule.push(temp);
          }
        } else {
          rule.push(temp);
        }
      }
    });

    return rule;
  }
};

const actions = {
  getNewCreatePoint({
    commit
  }, uid) {
    const result = pub.getNewCreatePoint(uid);

    result.then((data) => {
      if (data.data.newPoint) {
        commit("setNewCreatePoint", data.data.newPoint);
      }
    });
  },

  searchPoint({
    state,
    commit
  }) {
    const data = state.searchTemp;
    const searchData = _.mapValues({
      lat: "lat",
      lng: "lng",
      scale: "scale",
      eleType: "eleType",
      keywords: "keywords",
      favorite: "favorite",
      country: "country",
      city: "city",
      class: "class"
    }, (item, key) => {
      return data.query[item];
    });


    const result = pub.searchPoint(data.method, data.range, searchData, data.searchMethod, data
      .pagetoken, data.rangeKm);
    result.then((data) => {
      if (data.data.state) {
        commit("setSearchData", data.data);
      }
    });
  },

  moreSearchPoint({
    state,
    commit
  }, page) {
    const data = state.searchTemp;

    const searchData = _.mapValues({
      lat: "lat",
      lng: "lng",
      scale: "scale",
      eleType: "eleType",
      keywords: "keywords",
      favorite: "favorite",
      country: "country",
      city: "city",
    }, (item, key) => {
      return data.query[item];
    });

    const result = pub.searchPoint(data.method, data.range, searchData, data.searchMethod, page,
      data.rangeKm || 20);
    result.then((data) => {
      if (data.data.state) {
        commit("setMoreSearchData", data.data);
      }
    });
  }
};

const mutations = {
  setNewCreatePoint(state, data) {
    state.newCreatePoint.push(data);
  },
  setMoreSearchBox(state, data) {
    state.moreSearchShow = data;
  },

  setSearchTemp(state, data) {
    state.searchTemp = data;
  },

  setSearchData(state, data) {
    let pointTemp = [];
    let count = 0;
    let nextPage = "";
    state.newCreatePoint = [];

    if (data && data.result) {
      count = data.result.count;
      pointTemp = data.result.results;
      nextPage = data.result.next_page_token;
      state.searchResult.searchRange = data.result.searchrange;
    }

    state.searchResult.type = data.method;
    state.searchResult.data = pointTemp;
    state.searchResult.count = count;
    state.searchResult.nextpage_token = nextPage;
  },

  setMoreSearchData(state, data) {
    state.searchResult.data = state.searchResult.data.concat(data.result.results);
    state.searchResult.nextpage_token = data.result.next_page_token;
    state.searchResult.searchRange = data.result.searchrange;
  },

  setRange(state, data) {
    state.searchTemp.range = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
