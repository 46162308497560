import http from "./support"
export default {
  // getSchedule(method) {
  //   return http.get("Schedule/getSchedule/" + method, {}, {}, "getSchedule");
  // },

  async addFavorite(data) {
    const result = http.post("schedule/addFavorite", data);
    return result;
  },

  async getSchedule() {
    const result = http.get("schedule/getSchedule");
    return result;
  },

  async getScheduleDay(uid) {
    const result = http.get("schedule/getScheduleDay", {
      uid: uid
    });
    return result;
  },

  async getScheduleInfo(data) {
    const result = http.get("schedule/getScheduleInfo", data);
    return result;
  },

  async createSchedule(method, data) {
    const result = http.post("schedule/createSchedule", {
      method: method,
      data: data
    });
    return result;
  },

  async addSchedule(uid, data) {
    data.uid = uid;
    const result = http.post("schedule/addSchedule", data);
    return result;
  },

  async addStrokeOther(uid, data) {
    data.uid = uid;
    const result = http.post("schedule/addStrokeOther", data);
    return result;
  },

  async getSearchData(scope, query, type, pagetoken) {
    const result = http.post("schedule/searchNearPoint", {
      scope: scope,
      lat: query.lat,
      lng: query.lng,
      scale: query.scale,
      searchtext: query.searchtext,
      searchmehod: query.searchmethod,
      type: type,
      pagetoken: pagetoken
    }, {}, "NearPoint");

    return result;
  },

  async EditStart(data) {
    const temp = {
      uid: data.Schdedule,
      data: data.data
    };
    const result = http.post("schedule/editStart", temp);
    return result;
  },
  async getDistanceSpeed(data) {
    const result = http.post("schedule/computDistanceSpeed", data);
    return result;
  },
  async editPoint(uid, data) {
    data.uid = uid;
    const result = http.post("schedule/editPoint", data);
    return result;
  },

  async updateStrokeOther(uid, data) {
    data.uid = uid;
    const result = http.post("schedule/updateStrokeOther", data);
    return result;
  },

  async EditeScheduleInfo(uid, data) {
    const result = http.post("schedule/editeScheduleInfo", data);
    return result;
  },

  async changeSchedule(uid, data) {
    const temp = {
      uid: uid,
      data: data
    }
    const result = http.post("schedule/changeScheduleDay", temp);
    return result;
  },

  async CopySchedule(uid) {
    const result = http.post("schedule/copySchedule", {
      schedule: uid
    });
    return result;
  },

  async getOnePoint(uid) {
    const temp = {
      uid: uid
    }
    const result = http.get("schedule/getOnePoint", temp);
    return result;
  },

  async AddDay(uid, number) {
    const result = http.post("schedule/addScheduleDay", {
      uid: uid,
      number: number
    });
    return result;
  },

  uploadImage(place, data) {
    return http.post("Schedule/uploadImage/" + place, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  savePersonalPoint(data) {
    return http.post("schedule/savePersonalPoint", data);
  },
  deleteSchedule(uid, source, dayuid) {
    return http.get("schedule/deleteSchedule/" + uid + "/" + source, { dayuid: dayuid });
  },

  delMySchedule(uid) {
    return http.post("Schedule/delMySchedule", uid);
  },

  ShareUrl(data) {
    return http.post("Schedule/ShareUrl", data);
  },

  ChangeParallel(data) {
    return http.post("Schedule/ChangeParallel", data, {}, "ChangeParallel");
  },

  setLocationLog(data, schedule) {
    return http.get("SetLocationLog/" + schedule, data);
  },

  getShoeLog(schedule) {
    return http.get("getShoeLog/" + schedule);
  },

  getQuote(schedule) {
    return http.get("Schedule/getQuote/" + schedule);
  },

  updateDayListPoint(data) {
    return http.post("Schedule/updateDayListPoint", data);
  },

  getPoint(data) {
    return http.get("Schedule/getPoint/" + data);
  },

  getPersonalPoint() {
    return http.get("Searchdata/getPersonalPoint");
  },

  sendToCompany(data) {
    return http.post("Searchdata/sendToCompany", data);
  },

  getPersonalImage(data) {
    return http.get("Schedule/getPersonalImage/" + data.place_id);
  },

  getPersonalPhoto() {
    return http.get("Searchdata/getPersonalPhoto");
  },

  updateElement(data) {
    return http.post("Schedule/updateElement", data);
  }
}
