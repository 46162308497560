import api from "@/service/mapManger";

let lat = 25.046273;
let long = 121.517498;

const state = {
  mapData: {
    zoom: 15,
    mapcenter: [lat, long]
  },

  copyCoordinate: "",

  searchPoint: true,
  mapLine: true,
  mapPoint: true,

  showSearchPoint: "",
  searchPointInfo: {}
};

const getters = {};

const actions = {
  async changeZoom({
    state,
    commit
  }, data) {
    const awita = await commit("setZoom", data);
    return awita;
  },

  async changeMapCenter({
    state,
    commit
  }, data) {
    const awit = await commit("setMapCenter", data);
    return awit
  },

  distanceCount({
    state,
    commit
  }, data) {
    const result = api.googleDistanceCount(data);
    result.then((data) => {
      if (data.data.state) {
        commit("schedule/setOneScheduleDay", data.data.result, {
          root: true
        });
      }
    });
    return result;
  }
};

const mutations = {
  setZoom(state, zoom) {
    state.mapData.zoom = zoom;
  },

  setMapCenter(state, itude) {
    state.mapData.mapcenter = itude;
  },

  setSearchPoint(state, data) {
    state.searchPoint = data;
  },

  setMapLine(state, data) {
    state.mapLine = data;
  },

  setMapPoint(state, data) {
    state.mapPoint = data;
  },

  setShowSearchPoint(state, data) {
    state.showSearchPoint = data;
  },
  setCopyCoordinate(state) {
    state.copyCoordinate = state.mapData.mapcenter.join(",");
    navigator.clipboard.writeText(state.copyCoordinate);

    this.dispatch("Public/addAlert", {
      title: "複製座標",
      type: "success",
      msg: state.copyCoordinate
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
