import store from '@/store';
import Birth from '@/components/Input/Birth';

export default {
  name: 'ReSignPage',
  methods: {
    Login() {
      let vm = this;
      store.dispatch("user/getGoogle").then(function (data) {
        store.dispatch("user/resign", {
          method: "check",
          data: {
            email: data.Qt.zu,
            googleuid: data.Ca
          }
        }).then((check) => {
          if (check.data.result.msg) {
            vm.page = 999;
          } else {
            vm.page++;
            vm.data.account = vm.data.email = data.Qt.zu;
            vm.data.name = data.Qt.Ad;
            vm.data.photo = data.Qt.gL;
            vm.data.googleuid = data.Ca;
          }
        });
      }).catch((error) => {
        alert("註冊失敗");
      });
    },

    next(page) {
      if (page == 1 && this.data.email != "" && this.data.password) {
        store.dispatch("user/resign", {
          method: "check",
          data: {
            email: this.data.email
          }
        }).then((data) => {
          if (data.data.result.msg) {
            this.page = 999;
          } else {
            this.data.account = this.data.email;
            this.page++;
          }
        });
      } else if (page == 2) {
        const checkfield = ["birth", "name", "phone", "gender_id"];
        this.thischeck = checkfield.map((item, keys) => {
          if (this.data[item]) {
            if (item == "birth") {
              if (this.data[item].year && this.data[item].month && this.data[item]
                .date) {
                return;
              } else {
                return item;
              }
            } else {
              return;
            }
          } else {
            return item;
          }
        }).filter((item) => {
          return item;
        });

        if (this.thischeck.length == 0) {
          this.page++;
          this.thischeck = [];
        } else {
          store.dispatch("Public/addAlert", {
            type: "danger",
            title: "請務必填寫",
            msg: "此區欄位都是必填"
          });
        }
      } else {
        this.page++;
      }
    },
    pre(page) {
      if (page == 2) {
        this.data.account = this.data.email;
        this.data.password = "";
        this.page--;
      } else if (page == 999) {
        this.page = 1;
      } else {
        this.page--;
      }
    },
    finish() {
      const result = store.dispatch("user/resign", {
        method: "finish",
        data: this.data
      });
      result.then((data) => {
        if (data.data.result.inMember) {
          store.dispatch("Public/addAlert", {
            type: "success",
            title: "註冊完成",
            msg: "註冊完成"
          });
          if (this.caseOI) {
            this.caseOI(data.data.result);
          }
          this.Close();
        };
      })
    },
    Close() {
      $.magnificPopup.close();
    }
  },
  computed: {
    passwordshow() {
      return this.data.password.slice(0, 4) + "*******";
    },
    lang: {
      get() {
        return store.state.Public.lang.resign;
      }
    },
    langpub: {
      get() {
        return store.state.Public.lang.public;
      }
    }
  },
  data() {
    
    // this = 
    return {
      page: this.part || 1,

      pwdcheck: false,
      emailcheck: false,
      postcode: store.state.Public.postcode,
      thischeck: [],
      data: {
        account: "",
        password: "",
        name: "",
        googleuid: "",
        email: "",
        phone: "",
        engname: "",
        postelcode: "",
        location: "",
        gender_id: "",
        birth: {
          year: "",
          month: "",
          date: ""
        },
        photo: ""
      }
    };
  },
  watch: {
    "data.password"(data) {
      // const passwordcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      const passwordcheck = /^[0-9a-zA-Z]{6,}$/;
      this.pwdcheck = passwordcheck.test(data) || data.length >= 6;
    },
    "data.email"(data) {
      const emailcheck = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
      this.emailcheck = emailcheck.test(data);
    }
  },

  props: {
    part: Number,
    caseOI: Function
  },
  components: {
    Birth
  }
  // befor
};
