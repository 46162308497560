import http from "./support";
import _ from "lodash"
export default {
  async searchPoint(method, range, search, searchMethod, pagetoken,rangeKm) {
    // console.log(data);
    // const result = await http.post("schedule/searchPoint", data);
    // return result;

    const result = await http.post("schedule/searchPoint", {
      method: method,
      range: range,
      searchMethod: searchMethod,
      search: search,
      pagetoken: pagetoken,
      rangeKm:rangeKm
    }, {}, "NearPoint");

    return result;

  },

  PostCode() {
    return http.get("Public/PostCode");
  },

  Country() {
    return http.get("Public/Country");
  },

  SearchPostCode(Country, City) {
    return http.get("Public/SearchPostCode", {
      Country: Country,
      City: City
    });
  },

  checkEmailMember(tmp) {
    return http.get("Public/checkEmailMember", {
      uid: tmp.uid,
      text: tmp.email
    });
  },

  getdataObject(data) {
    const request = {
      table: data.table,
      page: data.page || 0,
      pagenumber: data.pagenumber || 20,
      orderBy: data.orderBy || {
        name: "orders",
        order: "desc"
      },
      search: data.search || {},
      searchtype: data.searchtype || 0, //1 嚴謹 / 0 模糊
      searchmethod: data.searchmethod || "", //group {method: group, field: uid}
      cancelfunc: data.cancelfunc || "",
      field: data.addfield || [], //addfield
      spinner: data.spinner == undefined ? 1 : data.spinner, //顯示轉圈圈
      defaultfield: data.defaultfield || "", //預設欄位
      setjoin: data.join || {}, // 撰寫join,
      orderRaw: data.orderRaw,
      searchfield: data.searchfield,
      pre: data.fieldpre
    }

    const t = this.get_data(request.table, request.page, request.pagenumber, request.orderBy,
      request
      .search, request.searchtype, request.searchmethod,
      request.cancelfunc, request.field, request.spinner, request.defaultfield, request.setjoin,
      request.orderRaw, request.searchfield, request.pre);

    return t;
  },

  get_data(table, page, pagenumber, orderBy, search, searchtype, searchmethod, cancelfunc, field,
    spinner, defaultfield, setjoin, orderRaw, searchfield, pre) {
    orderBy = orderBy ?
      orderBy : {
        name: "orders",
        order: "desc"
      };
    search = search ? search : {};
    searchtype = searchtype != undefined ? searchtype : 0;
    const dal = this.getdatainformation(table, page, pagenumber, orderBy, search, searchtype,
      searchmethod, field, defaultfield, searchfield, null, pre);
    dal.join = setjoin || {};
    orderRaw ? dal.orderRaw = orderRaw : "";
    return this.gettabledataajx(dal, spinner, cancelfunc);
  },

  getdatainformation(table, page, pagenumber, orderBy, search, searchtype, searchmethod, field,
    defaultfield, searchfield, rtable, pre) {
    if (search && search.date) {
      _.each(search.date, function (item, keys) {
        const isstartdate = false,
          isenddate = false;
        const errordata = [];
        if (item && item.start) {
          isstartdate = moment(item.start)._d;
          if (isstartdate != "Invalid Date" && isstartdate != "Invalid date") {
            item.start = item.start == "Invalid Date" || item.start == "Invalid date" ||
              item.start == "--" ? "" : moment(isstartdate).format("YYYY-MM-DD");
          } else {
            item.start = "";
          }
        }
        if (item && item.end) {
          isenddate = moment(item.end)._d;
          if (isenddate != "Invalid Date" && isenddate != "Invalid date") {
            item.end = item.end == "Invalid Date" || item.end == "Invalid date" || item
              .end == "--" ? "" : moment(isenddate).format("YYYY-MM-DD");
          } else {
            item.end = "";
          }
        }
      });
    }

    if (defaultfield && typeof (defaultfield) == "object") {
      const pretable = table.split(".")[0];
      const tags = ["id", "uid", "cmember", "umember", "sys_company"];
      defaultfield = _.filter(defaultfield, function (item, keys) {
        const field = item.split(".");
        const index = field.length - 1;
        return tags.indexOf(field[index]) == -1;
      });
      defaultfield.push(pretable + ".id")
      defaultfield.push(pretable + ".uid")
      defaultfield.push(pretable + ".cmember")
      defaultfield.push(pretable + ".umember")
      defaultfield.push(pretable + ".sys_company");
    }

    rtable = rtable || table;
    return {
      table: table,
      search: search,
      page: page,
      pagenumber: pagenumber,
      orderBy: orderBy,
      searchtype: searchtype,
      searchmethod: searchmethod ? searchmethod : "", //group
      addfield: field || "",
      field: defaultfield || "",
      searchfield: searchfield || "",
      rtable: rtable,
      pre: pre
    };
  },

  async gettabledataajx(dal, spinner, cancelfunc) {
    const table = dal.table;
    spinner = (spinner === false) ? false : true;
    cancelfunc = cancelfunc || "";

    const result = await http.post("Public/getData/" + table, dal, {}, cancelfunc, 2,
      true);

    return result;
  },

  async getMultiDataObject(data, spinner, cancelFunc) {
    spinner = (spinner === false) ? false : true;
    cancelFunc = cancelFunc || "";

    var temp = _.map(data,  (item, key) =>{
      var temp = this.getdatainformation(item.table, item.page, item.pagenumber, item.orderBy, item
        .search, item.searchtype, item.searchmethod, item.field, item.defaultfield, item
        .searchfield, item.rtable, item.pre);
      item.orderRaw ? (temp.orderRaw = item.orderRaw) : "";
      temp.join = item.join || {};
      return temp;
    });
    const result = await http.post("Public/getMultiDataObject", temp, {}, cancelFunc, 2,
      true);
    return result;
  },

  async autoSave(data) {
    // return ;
    const result = await http.post("Public/autoSave", data, {}, 'AutoSave', 1, true);
    return result;
  },

  async finderList(data) {
    const result = await http.get("finderlist", data, {}, "finderList", 1, true);
    return result;
  },

  async uploadfile(data){
    const result = await http.post("uploadfile", data, {}, "upload", 3, true);
    return result;
  },
  async GetWebImage(webUrl) {
    return await http.post("https://erp.dagee.tw/api/public/api/Public/GetWebImage", { webUrl: webUrl }, {},"GetWebImage",0,true);
  },

  async getNewCreatePoint(uid){
    return await http.get("schedule/getNewCreatePoint/" + uid)
  }
}
