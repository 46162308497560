import travel from '@/service/travel';
import _ from "lodash";

const state = {
  tranData: {
    product: [],
    list: []
  }
};
const getters = {
  packageUrl(state) {
    let url = process.env.VUE_APP_TRAVEL_URL;
    return _.map(state.tranData.product, (item, key) => {
      let code = item["outcode"] || item["uid"];
      return {
        name: item.name,
        url: url + "/" + code
      };
    });
  }
};
const actions = {
  transferToTravel({
    state,
    commit
  }, data) {
    const result = travel.transferToTravel(data);
    result.then((data) => {
      commit("setTranData", data.data.result);
    });
    return result;
  },

  getTransferProduct({
    state,
    commit
  }, data) {
    const result = travel.getTransferProduct(data);
    result.then((data) => {
      commit("setTranData", data.data.result);
    });
    return result;
  }
};
const mutations = {
  setTranData(state, data) {
    state.tranData.product = data.product;
    state.tranData.list = data.list;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
