import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';

Vue.use(Router);

// get router view
let getpage = (name, path = 'views') => () => {
  return import(`./${path}/${name}.vue`);
}

const REDIRECT_TIME_LIMIT = 3600000;
const defaultRoute = {
  name: 'index'
};

const redirect = () => {
  const now = new Date().valueOf();
  const local = JSON.parse(window.localStorage.getItem('vuex'));

  if (!local) {
    console.log("to index");
    return defaultRoute;
  }

  const {
    route,
    lastLaunch
  } = local;

  if ((now - lastLaunch) < REDIRECT_TIME_LIMIT) {
    console.log(route);
    return (!route.name || route.name == "thought") ? defaultRoute : {
      name: route.name
    };
  }

  return defaultRoute;
};

const router = new Router({
  mode: process.env.VUE_APP_MODEL,
  base: process.env.VUE_APP_URL,
  fallback: true,
  routes: [{
    name: "thought",
    path: '/',
    redirect: redirect(),
  }, {
    path: '/index',
    component: getpage("index"),
    children: [{
      name: 'index',
      path: '/index',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/Index"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯',
        header: true
      },
      redirect: {
        name: "schedule"
      }
    }, {
      name: 'personalPoint',
      path: '/personalPoint',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/PersonalPoint"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-景點編輯',
        header: true
      }
    }, {
      name: 'personalPhoto',
      path: '/personalPhoto',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/PersonalPhoto"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-景點編輯',
        header: true
      }
    }, {
      name: 'schedule',
      path: '/schedule',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/schedule"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-行程列表',
        header: true
      }
    }, {
      name: 'Edit',
      path: '/Edit/:Schedule',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/EditSchedule"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-行程編輯',
        search: true,
      }
    }, {
      name: 'show',
      path: '/show/:product',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/EditSchedule"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-行程編輯',
        search: true,
      }
    }, {
      name: 'Quote',
      path: '/Quote/:Schedule',
      components: {
        header: getpage("Public/headerinfo"),
        context: getpage("Context/Quote"),
        footer: getpage("Public/footerinfo")
      },
      meta: {
        title: '旅遊百匯-行程報價',
        header: true
      }
    }]
  }, {
    name: 'ShareQRcode',
    path: '/QRcode/:level/:scheduleuid/:sourcemember',
    component: getpage("ShareView"),
    meta: {
      title: '達跡旅行社',
    }
  }, {
    name: 'login',
    path: '/login',
    component: getpage("Login"),
    meta: {
      title: '達跡旅行社'
    }
  }, {
    name: 'logout',
    path: '/logout',
    meta: {
      title: '達跡旅行社'
    }
  }, {
    name: 'tranto',
    path: '/tranto/:Schedule',
    meta: {
      title: '轉換中'
    }
  }],
});

router.beforeEach(async (to, from, next) => {
  document.title = (to.meta && to.meta.title) ? to.meta.title : document.title;

  if (to.name == 'logout') {
    store.dispatch("user/logout").then((data) => {
      store.dispatch("Public/addAlert", {
        type: "success",
        title: store.state.Public.lang.Login.logoutsuccess,
        msg: store.state.Public.lang.Login.logoutsuccess
      });

      next({
        name: "login"
      });
    });
  } else if (to.name == "login") {
    next();
  } else if (to.name == "tranto") {
    const response = await store.dispatch("user/tranToSchedule", {
      Schedule: to.params.Schedule,
      token: to.query.token
    })

    if (response.status) {
      await store.dispatch("Public/getMountPublicData");
      next({
        name: "Edit",
        params: {
          Schedule: response.schedule
        }
      })
    } else {
      alert("無此使用者，將關閉畫面");
      window.opener = null;
      window.open("about:blank", "_self").close();
      window.close();
    }
  } else {
    const checkmemberfunc = store.dispatch("user/checkUser");
    
    const checkmember = to.meta.checkmember;
    const searchShow = to.meta.search || false;
    const headerShow = to.meta.header || false;
    
    store.commit("Public/setHeaderShow", headerShow);
    checkmemberfunc.then((data) => {
      store.commit("Public/setSearchBox", searchShow);
      store.commit("Public/setOpenSearch", false);

      if (to.name == 'index') {
        next();
        return;
      }

      if (checkmember) {
        if (store.state.user.Login.state) {
          next();
        } else {
          next({
            name: "index"
          });
        }
      } else {
        next();
      }
    }).catch((e) => {
      next({
        name: "login"
      })
    });
  }
});

router.afterEach(async (to, from, next) => {
  // 切換頁面後回到頁面最上面
  $('body, html').scrollTop(0, 500);
});

export default router;
